<template>
  <a-card bordered>
    <a-row :gutter="8">
      <a-col :xs="24" :sm="24" :md="12" :lg="8" :xl="4" :xxl="3">
        <a-date-picker
          :defaultValue="fromDate"
          @change="onChangeFromDate"
          style="width: 100%"
          placeholder="Ngày bắt đầu"
          format="DD/MM/YYYY"
        />
      </a-col>
      <a-col :xs="24" :sm="24" :md="12" :lg="8" :xl="4" :xxl="3">
        <a-date-picker
          :defaultValue="toDate"
          @change="onChangeToDate"
          style="width: 100%"
          placeholder="Ngày kết thúc"
          format="DD/MM/YYYY"
        />
      </a-col>
      <a-col v-if="isReportTotal == 1" :xs="24" :sm="24" :md="12" :lg="8" :xl="4" :xxl="3">
        <a-select
          showSearch
          allowClear
          style="width: 100%"
          :value="queryParam.staffAsmId"
          placeholder="Chọn vùng ASM"
          @change="handleChangeStaffAsm"
          :filterOption="filterOption"
        >
          <a-select-option v-for="item in staffAsmOptions" :value="item.value" :key="item.value">{{
            item.name
          }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xs="24" :sm="24" :md="12" :lg="8" :xl="4" :xxl="3">
        <a-select
          style="width: 100%"
          v-model="salonIdSelected"
          placeholder="Chọn salon"
          allowClear
          showSearch
          :filterOption="filterOption"
        >
          <a-select-option v-for="salon in salonOptions" :value="salon.id" :key="salon.id">{{
            salon.salonName
          }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xs="24" :sm="24" :md="24" :lg="16" :xl="8" :xxl="6">
        <a-radio-group name="radioGroup" :default-value="1" @change="onChangeCaculatorType">
          <a-radio :value="1">Tổng hợp giờ công </a-radio>
          <a-radio :value="2"> Chi tiết giờ công </a-radio>
        </a-radio-group>
      </a-col>
    </a-row>
    <a-row :gutter="8" style="margin-top: 14px">
      <a-col :xs="24" :sm="24" :md="12" :lg="8" :xl="4" :xxl="3">
        <a-select
          style="width: 100%"
          v-model="queryParam.staffType"
          placeholder="Chọn bộ phận"
          allowClear
          showSearch
          @change="handleChangeStaffType"
          :filterOption="filterOption"
        >
          <a-select-option v-for="staffType in staffTypeOptions" :value="staffType.value" :key="staffType.value">{{
            staffType.text
          }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xs="12" :sm="12" :md="12" :lg="4" :xl="4" :xxl="2">
        <a-button :style="{ width: '100%' }" icon="search" type="primary" @click="handleViewData"
          >Xem dữ liệu
        </a-button>
      </a-col>
      <a-col :xs="12" :sm="12" :md="12" :lg="4" :xl="4" :xxl="2">
        <a-button :style="{ width: '100%' }" icon="delete" @click="resetFilter">Reset Filter</a-button>
      </a-col>
    </a-row>
    <a-row :gutter="8" style="margin-top: 20px">
      <a-col>
        <a-table
          size="small"
          :columns="isReportTotal == 2 ? columnsDetail : columnsTotal"
          :dataSource="isReportTotal == 2 ? dataDetail : dataTotal"
          bordered
          :pagination="pagination"
          :loading="loading"
          :rowKey="(record, index) => index"
          :scroll="{ x: maxWidth }"
        >
          <span slot="stt" slot-scope="text, record">{{ record.stt }}</span>
          <span slot="id" slot-scope="text, record">{{ record.id }}</span>
          <span slot="position" slot-scope="text, record">{{ record.position }}</span>
          <span slot="basicTime" slot-scope="text, record">{{ record.basicTime }}</span>
          <span slot="overtime" slot-scope="text, record">{{ record.overtime }}</span>
          <span slot="totalTime" slot-scope="text, record">{{ record.totalTime }}</span>
          <span slot="totalStaff" slot-scope="text, record">{{ record.totalStaff }}</span>
          <span slot="guestReception" slot-scope="text, record">{{ record.guestReception }}</span>
          <span slot="customerTimePercent" slot-scope="text, record">{{ record.customerTimePercent }}</span>
          <!-- ---- -->
          <span slot="staffName" slot-scope="text, record">{{ record.staffName }}</span>
          <span slot="department" slot-scope="text, record">{{ record.department }}</span>
          <span slot="skillLevel" slot-scope="text, record">{{ record.skillLevel }}</span>
        </a-table>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import { getFactory } from '@/api'
import { mapGetters } from 'vuex'
import { normalize } from '@/utils'
import { mixin, mixinDevice } from '@/utils/mixin'
import moment from 'moment'
const StaffTypeRepository = getFactory('staffType')
const StaffRepository = getFactory('staff')
const SalonRepository = getFactory('salon')
const StynextStaticDataRepository = getFactory('stynextTimeKeepingReport')
const FlowTimeKeepingRepository = getFactory('timekeeping')

const columnsTotal = [
  {
    title: 'Vị trí',
    dataIndex: 'position',
    align: 'center',
  },
  {
    title: 'Giờ cơ bản',
    dataIndex: 'basicTime',
    align: 'center',
  },
  {
    title: 'Giờ tăng ca',
    dataIndex: 'overtime',
    align: 'center',
  },
  {
    title: 'Tổng giờ làm',
    dataIndex: 'totalTime',
    align: 'center',
  },
  {
    title: 'Tổng số người',
    dataIndex: 'totalStaff',
    align: 'center',
  },
  {
    title: 'Số lượt khách tiếp nhận',
    dataIndex: 'guestReception',
    align: 'center',
  },
  {
    title: 'Tỉ lệ khách/giờ',
    dataIndex: 'customerTimePercent',
    align: 'center',
  },
]

const columnsDetail = [
  {
    title: 'STT',
    dataIndex: 'stt',
    align: 'center',
  },
  {
    title: 'ID',
    dataIndex: 'id',
    align: 'center',
  },
  {
    title: 'Tên nhân viên',
    dataIndex: 'staffName',
    align: 'center',
  },
  {
    title: 'Bộ phận',
    dataIndex: 'department',
    align: 'center',
  },
  {
    title: 'Skill Level',
    dataIndex: 'skillLevel',
    align: 'center',
  },
  {
    title: 'Giờ cơ bản',
    dataIndex: 'basicTime',
    align: 'center',
  },
  {
    title: 'Giờ tăng ca',
    dataIndex: 'overTime',
    align: 'center',
  },
  {
    title: 'Tổng giờ làm',
    dataIndex: 'totalTime',
    align: 'center',
  },
]

export default {
  mixins: [mixin, mixinDevice],
  computed: {
    ...mapGetters(['user']),
  },
  data() {
    return {
      salonIdSelected: undefined,
      dataTotal: [],
      dataDetail: [],
      isReportTotal: 1,
      queryParam: {
        staffId: undefined,
        toDate: undefined,
        fromDate: undefined,
        staffAsmId: undefined,
      },
      pagination: { pageSizeOptions: ['10', '20', '50', '100', '1000'], showSizeChanger: true },
      maxWidth: 0,
      indexColumnsDefault: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
      columnsDefault: columnsTotal,
      columnsTotal: columnsTotal,
      columnsDetail: columnsDetail,
      fromDate: moment().startOf('month'),
      toDate: moment(),
      total: 0,
      staffTypeOptions: [],
      staffOptions: [],
      staffAsmOptions: [],
      salonOptions: [],
      salons: [],
      loading: false,
      loadingExport: false,
      totalSalaryCiCo: 0,
      visible: false,
      isDisabled: true,
      searchStaff: '',
    }
  },
  methods: {
    handelSearchStaff(e) {
      this.searchStaff = ''
      if (e != null) {
        this.searchStaff = e
      }
      this.getStaff(this.searchStaff, 20)
    },
    handelScrollStaff(e) {
      const element = e.target
      if (element.scrollHeight == element.clientHeight + element.scrollTop && this.staffOptions.length >= 20) {
        let limit = this.staffOptions.length + 20
        this.getStaff(this.searchStaff, limit)
      }
    },
    resetFilter() {
      this.queryParam = {
        staffId: undefined,
        toDate: undefined,
        fromDate: undefined,
        staffAsmId: undefined,
        staffType: undefined,
      }
      this.salonIdSelected = undefined
    },
    onChangeCaculatorType(e) {
      this.isReportTotal = e.target.value
    },
    moment,
    onChangeToDate(date, dateString) {
      this.toDate = date
    },
    onChangeFromDate(date, dateString) {
      this.fromDate = date
    },
    async handleViewData() {
      this.pagination = false
      await this.getData()
      this.pagination = { pageSizeOptions: ['10', '20', '50', '100', '1000'], showSizeChanger: true }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j]
        })
      )
    },
    changeSalon(record) {
      this.visibleChangeSalon = true
      this.staff = record
    },
    async getData() {
      if (!this.fromDate || !this.toDate) {
        this.$message.warn('Vui lòng chọn thời gian.')
        return
      }
      if (this.fromDate.isAfter(this.toDate)) {
        this.$message.warn('Vui lòng chọn ngày bắt đầu nhỏ hơn ngày kết thúc.')
        return
      }
      if (!this.queryParam.staffType && !this.queryParam.salonId && !this.queryParam.staffAsmId) {
        this.$message.warn('Vui lòng chọn salon, chọn vùng hoặc chọn bộ phận.')
        return
      }
      this.dataDetail = []
      this.dataTotal = []
      let salonIds = undefined
      let isASMView = false
      if (!this.queryParam.salonId && this.queryParam.staffAsmId) {
        isASMView = true
        salonIds = this.salonOptions.map((x) => {
          return x.id
        })
      } else {
        salonIds = [this.queryParam.salonId]
      }
      let listSalon = salonIds != null ? salonIds : []
      if (isASMView && (!salonIds || salonIds.length == 0)) {
        this.$message.warn('ASM không quản lý salon nào.')
        return false
      }
      let params = {
        fromDate: this.fromDate.format('YYYY-MM-DD'),
        toDate: moment(this.toDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        salonIds: listSalon.toString(),
        departmentId: parseInt(this.queryParam.staffType),
        salonId: listSalon.toString(),
      }
      this.loading = true
      try {
        await this.fetchData(params)
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },
    async fetchData(params) {
      try {
        if (this.isReportTotal == 2) {
          let response = await FlowTimeKeepingRepository.timekeepingReportDetail(params)
          if (response != undefined && response.length > 0) {
            this.dataDetail = response.map((m, index) => {
              return {
                stt: index + 1,
                id: m.staffId,
                staffName: m.fullname,
                department: m.departmentName,
                skillLevel: m.skillLevelName,
                basicTime: m.totalNumberOfWorkHourBasic,
                overTime: m.totalNumberOfWorkHourOver,
                totalTime: m.totalNumberOfWorkHour,
              }
            })
          } else {
            this.$message.warn('Không có dữ liệu.')
          }
        } else {
          let response = await StynextStaticDataRepository.getTimeKeepingTotalReportStaff(params)
          if (response != undefined && response.length > 0) {
            this.dataTotal = response.map((m) => {
              let percent = m.numberOfBill / m.numberOfWorkHour
              percent = isNaN(percent) ? 0 : percent
              percent = m.numberOfWorkHour > 0 ? percent : 0
              percent = Math.round(percent * 100) / 100
              let department = this.staffTypeOptions.find((mm) => mm.value == m.departmentId)
              return {
                position: department != undefined ? department.text : m.departmentId,
                basicTime: m.numberOfWorkHourBasic,
                overtime: m.numberOfWorkHourOver,
                totalTime: m.numberOfWorkHour,
                totalStaff: m.staffCount,
                guestReception: m.numberOfBill,
                customerTimePercent: percent,
              }
            })
          } else {
            this.$message.warn('Không có dữ liệu.')
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getStaffType() {
      let staffTypes = await StaffTypeRepository.getCustomPath('selected-value')
      if (staffTypes && Array.isArray(staffTypes)) {
        this.staffTypeOptions = staffTypes
      }
    },
    async getListStaffAsm() {
      this.staffAsmOptions = await StaffRepository.getListStaffAsm()
      if (!this.$can(this.$permission.viewAllData, this.$route.path)) {
        this.staffAsmOptions = this.staffAsmOptions.filter((x) => x.value == this.user.userId)
      }
    },
    async getSalons() {
      if (this.$can(this.$permission.viewAllData, this.$route.path)) {
        this.salons = await SalonRepository.getAll()
      } else {
        this.salons = this.user.listSalon
      }
      this.salonOptions = this.salons
    },
    handleChangeStaffType(value) {
      this.queryParam.staffType = value
    },
    async getStaff(value, limit) {
      this.isDisabled = true
      this.queryParam.staffId = undefined
      let queryStaff = this.queryParam
      queryStaff.searchValue = value
      queryStaff.limit = limit
      let response = await StaffRepository.getSelectListStaff_V2(queryStaff)
      if (!this.$can(this.$permission.viewAllData, this.$route.path)) {
        response = response.filter((x) => {
          const salonExists = this.user.listSalon.find((l) => l.id === x.salonId)
          if (salonExists) {
            return true
          }
          return false
        })
      }
      this.staffOptions = response
      this.isDisabled = false
    },
    async getStaffOptions() {
      let queryStaff = this.queryParam
      queryStaff.searchValue = ''
      queryStaff.limit = 0
      let response = await StaffRepository.getSelectListStaff_V2(queryStaff)
      if (!this.$can(this.$permission.viewAllData, this.$route.path)) {
        response = response.filter((x) => {
          const salonExists = this.user.listSalon.find((l) => l.id === x.salonId)
          if (salonExists) {
            return true
          }
          return false
        })
      }
      this.staffOptions = response
    },
    async getStaffBySalonId(salonId) {
      let queryStaff = this.queryParam
      queryStaff.searchValue = ''
      queryStaff.limit = 0
      if (this.isBySalon) {
        var query = {
          salonId: salonId,
          staffType: this.queryParam.staffType,
          fromDate: this.fromDate.format('YYYY-MM-DD'),
          toDate: moment(this.toDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        }
        let response = await StaffRepository.getSelectListStaffBySalon(query)
        return response
      } else {
        let response = await StaffRepository.getSelectListStaff_V2(queryStaff)
        response = response.filter((x) => x.salonId === salonId)
        return response
      }
    },
    handleChangeStaffAsm(value) {
      this.queryParam.staffAsmId = value
      this.salonOptions = []
      this.queryParam.salonId = undefined
      let item = this.staffAsmOptions.find((x) => x.value === value)
      if (item) {
        this.salonOptions = this.salons.filter((x) => {
          return item.salonIds.indexOf(x.id) !== -1
        })
      } else {
        this.salonOptions = this.salons
      }
      this.searchStaff = ''
    },
    handleChangeStatus(value) {
      this.queryParam.status = value
      this.searchStaff = ''
      this.getStaff(this.searchStaff, 20)
    },
    getNo(index) {
      return this.queryParam.limit * (this.queryParam.page - 1) + (index + 1)
    },
    filterOption(input, option) {
      input = normalize(input.toLowerCase())
      return normalize(option.componentOptions.children[0].text.toLowerCase()).indexOf(input) >= 0
    },
    formatMoney(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
  },
  created() {
    this.getStaffType()
    this.getListStaffAsm()
    this.getSalons()
    this.getStaff(this.searchStaff, 20)
  },
  watch: {
    salonIdSelected() {
      this.queryParam.salonId = this.salonIdSelected
      console.log(this.queryParam.salonId)
    },
    isReportTotal() {
      this.resetFilter()
      this.getSalons()
      if (this.isReportTotal == 1) {
        this.dataTotal = []
      } else {
        this.dataDetail = []
      }
    },
  },
}
</script>
<style lang="less" scoped>
.lable-number {
  padding-left: 10px;
  color: red;
}
.selected-columns {
  display: flex;
  justify-content: flex-end;
}
.btn-green {
  background: #1da57a;
  border: 1px solid #1da57a;
  &:hover {
    background: #46bc97;
  }
}
</style>
